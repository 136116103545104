<template>
  <div class="vg_wrapper">
    <el-card>
      <publicTips></publicTips>
      <div class="vd_search_group">
        <el-form :inline="true" label-width="120px" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMstfsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="物料编号:">
                <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料名称:">
                <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="出入库类型:">
                <el-select size="small" v-model="searchForm.mstf_type" placeholder="请选择出入库类型" clearable>
                  <el-option v-for="item in mstfType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="潘通色号:">
                <el-input size="small" v-model="searchForm.mtrl_color" clearable placeholder="请填写潘通色号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="库位:">
                <el-select size="small" filterable v-model="searchForm.qrcd_bin" placeholder="请选择库位" clearable>
                  <el-option v-for="item in binList" :key="item.id" :label="item.param1" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料类型:">
                <el-select size="small" v-model="searchForm.mtrl_type" placeholder="请选择物料类型" clearable>
                  <el-option v-for="item in mtrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="颜色大类:">
                <el-select size="small" v-model="searchForm.colr_class" placeholder="请选择颜色大类" clearable multiple filterable>
                  <el-option v-for="item in optnColorList" :key="item.param1" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="所属部门:">
                <selectSectionType ref="selectSectionType" @selectStatusRow="getStatusVal2" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="16">
              <el-form-item label="时间范围:">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMstfsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow(0)" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增入库</el-button>
        <el-button type="warning" size="small" @click="addRow(1)" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增出库</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border v-loading="loadFlag">
            <el-table-column label="物料编号" prop="mtrl_no" />
            <el-table-column label="物料图片" align="center">
              <template slot-scope="scope">
                <el-popover v-if="scope.row.imge_url" placement="right" width="400" trigger="hover">
                  <div>
                    <el-image style="width: 100%" class="vd_popimg" :src="formatPic(scope, 'l')" fit="fill"> </el-image>
                  </div>
                  <el-image slot="reference" class="vd_elimg" :src="formatPic(scope, 's')" fit="fill"> </el-image>
                </el-popover>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料名称" prop="mtrl_name" show-overflow-tooltip />
            <el-table-column label="物料类型" prop="mtrl_type" :formatter="formatLeavType" />
            <el-table-column label="潘通色号" prop="mtrl_color" show-overflow-tooltip>
              <template slot-scope="scope">
                <div v-if="scope.row.mtrl_color">
                  <div class="vd_dis">
                    <div :class="{ vd_div: scope.row.colr_html }" :style="{ 'background-color': scope.row.colr_html }"></div>
                    <span>{{ scope.row.mtrl_color }}</span>
                  </div>
                </div>
                <div v-else class="vg_9f9a9a">暂无</div>
              </template>
            </el-table-column>
            <el-table-column prop="colr_class" label="颜色大类" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.colr_class">
                  {{ scope.row.colr_class }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="物料单位" prop="mtrl_unit">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_unit">
                  {{ scope.row.mtrl_unit}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column> -->
            <el-table-column label="出入库类型" prop="mstf_type">
              <template slot-scope="scope">
                <span v-if="scope.row.mstf_type === 0 || scope.row.mstf_type === 1">
                  {{ scope.row.mstf_type | formatMstfType }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="库位" prop="qrcd_bin_no">
              <template slot-scope="scope">
                <span v-if="scope.row.qrcd_bin_no">
                  {{ scope.row.qrcd_bin_no }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料毛高" prop="mtrl_thick">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_thick">
                  {{ scope.row.mtrl_thick | formaUnitH }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="mstf_remark" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="scope.row.mstf_remark">
                  {{ scope.row.mstf_remark }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料数量" prop="mtrl_num" :formatter="formatMtrlNum" />
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" />
            <!-- <el-table-column label="单据状态" :show-overflow-tooltip='true' align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{helper.getStatusName(scope.row.status).name}}</el-tag>
              </template>
            </el-table-column> -->
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mstfAPI } from '@api/modules/mstf';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import selectSectionType from '@/views/component/selectSectionType';
import publicTips from '@/views/component/publicTips';
import helper from '@assets/js/helper.js';

export default {
  name: 'MstfList',
  components: {
    pubPagination,
    publicTips,
    selectSectionType
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        mstf_type: null,
        mtrl_name: null,
        mtrl_no: null,
        qrcd_bin: null,
        mtrl_color: null,
        mtrl_type: null,
        colr_class: [],
        timeValue: {
          startTime: null,
          endTime: null
        },
        belo_dept_id: null //所属部门
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      currentPage: 1,
      binList: [],
      mstfType: [
        { id: 0, label: '入库' },
        { id: 1, label: '出库' }
      ],
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      optnColorList: [],
      isDyj: true,
      beloDeptId: null
    };
  },
  created() {
    this.getDepartment();
    this.initData();
  },
  filters: {
    // 出入库类型
    formatMstfType(row) {
      if (row === 0) {
        return '入库';
      } else if (row === 1) {
        return '出库';
      }
    },
    formaUnitH(row) {
      return helper.reservedDigits(row) + '毫米';
    }
  },
  watch: {
    $route(to, from) {
      if (from.path === '/mstf_add' || from.path === '/mstf_addO') {
        this.getMstfsList();
        this.getBinList();
      }
    }
  },
  methods: {
    initData() {
      this.getMstfsList();
      this.getBinList();
      this.getOptnColor();
    },
    //获取颜色大类option
    getOptnColor() {
      get(optnAPI.getAllContent, { perm_id: 10010 })
        .then(res => {
          if (res.data.code === 0) {
            this.optnColorList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
      // this.getDepartment()
    },
    //判断是否 是DYJ员工
    getDepartment() {
      let userInfo = this.$cookies.get('userInfo');
      if(userInfo.user_name === 'admin'){
        this.searchForm.belo_dept_id = null;
        this.isDyj = false;
        return;
      }
      if (userInfo.dept_id === 2 || userInfo.dept_id === 17) {
        this.searchForm.belo_dept_id = userInfo.dept_id + '';
        this.beloDeptId = userInfo.dept_id + '';
        this.isDyj = true;
      } else {
        this.searchForm.belo_dept_id = null;
        this.isDyj = false;
      }
    },
    // 获取库位信息
    getBinList() {
      get(optnAPI.getAllContent, { perm_id: 10006 })
        .then(res => {
          if (res.data.code === 0) {
            this.binList = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取出入库信息
    getMstfsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      let colr_class = null;
      if (this.searchForm.colr_class.length > 0) {
        colr_class = this.searchForm.colr_class.join(',');
      }
      get(mstfAPI.getMstfs, {
        mtrl_no: this.searchForm.mtrl_no,
        mtrl_name: this.searchForm.mtrl_name,
        qrcd_bin: this.searchForm.qrcd_bin,
        mstf_type: this.searchForm.mstf_type,
        mtrl_color: this.searchForm.mtrl_color,
        colr_class,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        page_no: this.currentPage,
        mtrl_type: this.searchForm.mtrl_type,
        belo_dept_id: this.searchForm.belo_dept_id
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        mstf_type: null,
        mtrl_name: null,
        mtrl_no: null,
        qrcd_bin: null,
        colr_class: [],
        mtrl_color: null,
        timeValue: {
          startTime: null,
          endTime: null
        }
      };
      if (this.isDyj) {
        this.searchForm.belo_dept_id = this.beloDeptId;
      } else {
        this.searchForm.belo_dept_id = null;
        this.$refs.selectSectionType.value2 = '全部';
      }
      this.loadFlag = true;
      this.currentPage = 1;
      this.getMstfsList();
      this.getBinList();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getMstfsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getMstfsList();
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // //新增
    addRow(val) {
      const permId = this.$route.query.perm_id;
      if (val === 0) {
        this.jump(`/mstf_add?perm_id=${permId}`);
      } else if (val === 1) {
        this.jump(`/mstf_addO?perm_id=${permId}`);
      }
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getMstfsList();
    },
    // 图片类型
    formatPic(scope, type) {
      return this.helper.picUrl(scope.row.imge_url, type, scope.row.create_time);
    },
    // 物料类型
    formatLeavType(row) {
      if (row.mtrl_type === 0) {
        return '原面料';
      } else if (row.mtrl_type === 1) {
        return '辅料';
      } else if (row.mtrl_type === 2) {
        return '包材';
      } else if (row.mtrl_type === 3) {
        return '加工面料';
      }
    },
    formatMtrlNum(row) {
      return this.helper.haveFour(row.mtrl_num) + ' ' + row.mtrl_unit;
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    //查询范围
    getStatusVal2(val) {
      this.searchForm.belo_dept_id = val;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
  margin-top: 5px;
}
.vd_dis {
  display: flex;
}
.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}
</style>
